const referrer = document.referrer;

console.log('referrer ',referrer);

const setRecommendPage = () => {
  const currentUrl = window.location.href;
  history.replaceState({ obem: 1 }, "");
  history.pushState(null, '', currentUrl);
}

if (!(referrer.includes('spo-revo.com') || referrer.includes('sporevo-staging') || referrer.includes('localhost')) && (history.state == null)) {

  const userAgent = window.navigator.userAgent.toLowerCase();
  if(userAgent.indexOf('edge') != -1) {
    console.log('edge');
    setRecommendPage()
  } else if(userAgent.indexOf('chrome') != -1) {
    console.log('chrome');
    setRecommendPage()
  } else if(userAgent.indexOf('safari') != -1) {
    console.log('safari');
    let eventTriggered = false;
    window.addEventListener("touchend", () => {
      if(!eventTriggered){
        eventTriggered = true;
        setRecommendPage()
      }
    }, { once: true });
    window.addEventListener("mousedown", () => {
      if (!eventTriggered) {
        eventTriggered = true;
        setRecommendPage()
      }
    }, { once: true });
  } else if(userAgent.indexOf('firefox') != -1) {
    console.log('firefox');
    setRecommendPage()
  } else if(userAgent.indexOf('opera') != -1) {
    console.log('opera');
    setRecommendPage()
  } else {
    console.log('other');
    setRecommendPage()
  }
}

window.addEventListener('popstate', function(event) {
  console.log('popstate');
  console.log(window.history.state)
  if(this.window.history.state && this.window.history.state.obem == 1){
    this.fetch('/crowdfunding_recommend')
    .then(response => response.text())
    .then(text => {
      document.open();
      document.write(text);
      document.close();
    })
  }
});

// scrollの比率を取得
window.addEventListener('scroll', function(event) {
  const rateHeight = window.scrollY / document.body.clientHeight;
  if(rateHeight > 0.25){
    sessionStorage.setItem('crowdfunding-recommend-quarter-scroll', true);
  }
});

